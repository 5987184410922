import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'
import TNL from 'svgs/tnl.svg'
import { Header, Layout } from 'components/global'

const PromotionBody = styled.section`
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: var(--offWhite);
  section {
    width: 90%;
    padding: 0 0 2rem 0;
    margin: var(--auto);
    color: var(--darkGrey);
    max-width: 1000px;
    p,
    ul,
    strong {
      font-size: 1rem;
    }
    a {
      color: var(--purple);
      font-weight: 600;
    }
    h3 {
      color: var(--purple);
      font-size: 1.95rem;
    }
    p {
      margin: 0.5rem 0;
    }
    .img {
      text-align: center;

      width: 90%;
      svg {
        max-width: 500px;
        margin: 2rem 0;
      }
    }
    div {
      margin-top: 1rem;
      h4 {
        color: var(--purple);
        font-size: 1.05rem;
      }
    }

    .spacer {
      display: grid;
      grid-template-columns: 30% 1fr;
      grid-gap: 2rem;
      @media ${media.md} {
        grid-template-columns: 23% 1fr;
      }
      strong,
      p {
        margin: 0;
      }
      li {
        margin: 0.5rem 0;
      }
      ol {
        padding-left: 25px;
        li {
          font-weight: 500;
        }
      }
    }
    .long {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
      @media ${media.md} {
        grid-template-columns: 20% 1fr;
      }
      ul {
        width: 90%;
        margin: 0 auto;
        &:nth-child(1) {
          li {
            margin-top: 0;
          }
        }
      }
    }
    .tc {
      margin-top: 1.5rem;
      li {
        margin: 0.5rem 0;
      }
    }
  }
`

const TNLpromotion = () => {
  const meta = {
    title: 'The National Lottery | Arnold Clark Cup',
  }
  return (
    <Layout title={meta.title}>
      <Header />
      <PromotionBody>
        <section>
          <div className="img">
            <TNL />
          </div>
          <h3>See the England Women’s Football Team in Action! </h3>
          <p>
            Thanks to The National Lottery, Lottery players who purchase a
            ticket for any England match at the{' '}
            <strong>Arnold Clark Cup</strong> taking place in February 2022 can
            take a friend for free!
          </p>
          <p>
            The first 400 fans who buy an Adult ticket to each England match
            via&nbsp;
            <a
              href="http://www.thefa.com/tickets"
              target="_blank"
              rel="noopener noreferrer"
              data-auto-recognition="true"
            >
              www.thefa.com/tickets
            </a>
            &nbsp;from 9am, 17 December 2021 and enter the code TNLC2022 will
            receive a free Adult or Concession ticket.
          </p>
          <p>
            All you need to do to qualify is to have bought a National Lottery
            ticket &ndash; in store or online &ndash; any time on or after 1
            November 2021 and be able to show proof of purchase on match day.
          </p>
          <div>
            <h4>
              Promotion Terms and Conditions The National Lottery Arnold Clark
              Cup
            </h4>
            <p>Ticket Promotion (the ‘Promotion’)</p>
          </div>
          <div className="spacer">
            <strong>Terms and Conditions:</strong>
            <p>The Arnold Clark Cup</p>
          </div>
          <div className="spacer">
            <strong>Promoter: </strong>
            <p>
              Pitch International LLP, 27 Brewhouse Lane, Putney Wharf, London
              SW15 2JX
            </p>
          </div>
          <div className="spacer">
            <strong>Minimum Age: </strong>
            <p>18</p>
          </div>
          <div className="spacer long">
            <strong>Details of Promotion: </strong>
            <ul>
              <ul>
                <li>
                  This offer is open to National Lottery players who can provide
                  proof of purchase of a valid National Lottery ticket
                </li>
                <li>
                  The offer is for a free adult or concession ticket, redeemable
                  by purchasing one adult ticket to any one of the following
                  three
                </li>
                <li>Arnold Clark Cup matches in which England are playing:</li>
                <ol>
                  <li>
                    Thursday 17 February 2022, KO 19:30 <br />
                    England v Canada at Riverside Stadium, Middlesbrough
                  </li>
                  <li>
                    Sunday 20 February 2022, KO 15:15 <br />
                    England v Spain at Carrow Road Stadium, Norwich
                  </li>
                  <li>
                    Wednesday 23 February 2022, KO 19:30 <br />
                    England v Germany at Molineux Stadium, Wolverhampton
                  </li>
                </ol>
                <li>
                  All National Lottery games (including National Lottery
                  draw-based games and National Lottery scratchcards) purchased
                  in-store and online/via app qualify to redeem this offer.
                </li>
                <li>
                  Ticket purchases for the match can be made through
                  <a href="http://www.thefa.com/tickets">
                    {' '}
                    www.thefa.com/tickets
                  </a>{' '}
                  from 9am, 17 December 2021
                </li>
                <li>
                  Proof of purchase of a National Lottery game can be either
                  hard copy ticket or digital and must be presented alongside
                  your match ticket if requested by a match steward on the day
                  of the game. Photos of physical tickets won&rsquo;t be
                  accepted.
                </li>
                <li>
                  The date of draw / purchase must be on or after 1 November
                  2021.
                </li>
                <li>
                  Offer is subject to availability; the first 400 adults to
                  redeem the offer for each of the three England matches will
                  qualify for a free ticket.
                </li>
                <li>
                  In the event of queries on the day, the venue&rsquo;s decision
                  is final.
                </li>
                <li>This offer is not exchangeable.</li>
              </ul>
            </ul>
          </div>
          <section className="tc">
            <ul>
              <li>
                Applicants must be based in the United Kingdom. Employees of the
                Promoter or anyone professionally connected to this Promotion
                (including Camelot UK Lotteries Limited) and members of their
                immediate families are not permitted to benefit from this
                Promotion.
              </li>
              <li>
                Applicants must be the Minimum Age (18) to benefit from this
                Promotion.
              </li>
              <li>Maximum one ticket per applicant.</li>
              <li>
                All Promotion applicants confirm that they have the right to
                submit the information used to benefit from this Promotion in
                accordance with these terms and conditions and they have not
                used the personal data of any third party.
              </li>
              <li>
                The Promoter shall have the right, where necessary, to undertake
                all such action as is reasonable to protect itself against
                fraudulent or invalid claims including, without limitation, to
                require further verification as to the identity, age, and other
                relevant details of a ticketholder.
              </li>
              <li>
                All tickets are non-exchangeable, non-transferable, and not
                redeemable for cash. In the event of unforeseen circumstances or
                circumstances outside the Promoter&rsquo;s control, the Promoter
                reserves the right to cancel the tickets. Travel and
                accommodation are not included and the Promoter accepts no
                responsibility for any costs associated with the ticket.
              </li>
              <li>
                The ticketholder agrees to comply with all conditions of entry
                applicable at the stadium and also comply with all ticket terms
                and conditions.
              </li>
              <li>
                The Promoter accepts no responsibility for any damage, losses,
                liabilities (including those which were reasonably foreseeable,
                or which we would have anticipated that you might incur due to a
                special circumstance of which we were aware), injury or
                disappointment incurred or suffered by you in connection with
                this Promotion. Nothing shall exclude the liability of the
                Promoter for fraud or for death or personal injury as a result
                of the Promoter&rsquo;s negligence.
              </li>
              <li>
                The Promoter reserves the right at any time and from time to
                time to modify or discontinue, temporarily or permanently, this
                Promotion with or without prior notice due to reasons outside of
                its control, or in exceptional circumstances.
              </li>
              <li>
                If any of these terms and conditions should be determined to be
                illegal, invalid or otherwise unenforceable then it shall be
                severed and deleted from these terms and conditions and the
                remaining terms and conditions shall survive and remain in full
                force and effect.
              </li>
              <li>
                These terms and conditions are governed by the laws of England
                and Wales. Any disputes arising under or in connection with them
                shall be subject to the exclusive jurisdiction of the courts of
                England, subject to any mandatory provisions of local laws. If
                you are resident in Scotland, you may bring your claim before
                the Scottish or English courts.
              </li>
            </ul>
          </section>
        </section>
      </PromotionBody>
    </Layout>
  )
}

export default TNLpromotion
